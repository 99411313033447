<template>
    <div>
        <section class="login">
            <div class="half_inner height_half_inner">
                <header class="section_header header_flex">
                    <h2 class="text_xxl text_white text_300">Recuperar contraseña</h2>
                    <div class="breadcrumbs">
                        <ul>
                            <li>
                                <router-link to="/" class="breadcrumbs_item">Home<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z"></path></svg></router-link>
                            </li>
                            <li>
                                <router-link to="/Login" class="breadcrumbs_item">Login<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z"></path></svg></router-link>
                            </li>
                            <li>
                                <span class="breadcrumbs_item">Recuperar Contraseña</span>
                            </li>
                        </ul>
                    </div>
                </header>
                <div v-if="!slug" class="content_block padded rounded white">
                    <div class="login_block grid grid_pad flex_space">
                        <div class="grid_col grid_col_1_2">
                            <div class="grid_col_box">
                                <h2 class="text_l mb30">Para recuperar tu contraseña introduce tu email en este campo.</h2>
                            </div>
                        </div>
                        <div class="grid_col grid_col_1_2">
                            <div class="grid_col_box">
                                <form class="form" @submit.prevent="recuperar">
                                    <div class="input_block">
                                        <input type="email" v-model="email" placeholder="Email" required/>
                                    </div>
                                    <div class="input_block">
                                        <button type="text">Enviar</button>
                                    </div>
                                    <div class="message" :class="msg.type" v-if="msg">
                                        <h3>{{msg.text}}</h3>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="slug" class="content_block padded rounded white">
                    <div class="grid grid_pad flex_space">
                        <div class="grid_col grid_col_1_2">
                            <div class="grid_col_box">
                                <h2 class="text_l mb30">A continuación, intruduce tu nueva contarseña</h2>
                            </div>
                        </div>
                        <div class="grid_col grid_col_1_2">
                            <div class="grid_col_box">
                                <form class="form" @submit.prevent="resetear">
                                    <div class="input_block">
                                        <input type="password" placeholder="Contraseña" required/>
                                    </div>
                                    <div class="input_block">
                                        <input type="password" placeholder="Repite Contraseña" required>
                                    </div>
                                    <div class="input_block">
                                        <button type="password">Enviar</button>
                                    </div>
                                    <div class="input_block">
                                        <div class="legal_line">
                                            <p class="legal">Todos los campos son imprescindibles</p>
                                        </div>
                                    </div>
                                    <div class="message" :class="msg.type" v-if="msg">
                                        <h3>{{msg.text}}</h3>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'Recuperar',
    data: function(){
        return{
            slug: null,
            email: null,
            msg: null
        }
    },
    created: function(){
        this.slug = this.$route.params.slug;

    },
    methods: {
        recuperar: function(){
            if(!this.email || this.email.trim() == ''){
                this.msg = {
                    type: 'ko',
                    text: 'Falta el email'
                }
                return ;
            }
            this.msg = null;

            axios
                .get(process.env.VUE_APP_USER+'reset', {
                    email: this.email
                })
                .then(response => {
                    let rs = response.data;
                    if(rs.state == 'OK'){
                        this.msg = {
                            type: 'ok',
                            text: 'Enviado correctamente, revisa tu email para continuar'
                        }
                    }
                    else{
                        this.msg = {
                            type: 'ko',
                            text: rs.msg
                        }
                    }
                })
                .catch(error => this.$parent.error = 'Hubo un error de conexion. intentelo mas tarde o contacte con administracion')
        }
    }
}
</script>